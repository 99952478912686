@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

html,body{min-height:100vh;}

body {
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
}
